import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const MessengerApp = lazyWithRetries(() => import("./MessengerApp"));

/**
 * The chat app config.
 */
const MessengerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "chat",
      element: <MessengerApp />,
      auth: [Role.ALL_CONVERSATIONS, Role.ONLY_ASSIGNED_CONVERSATIONS],
    },
  ],
};

export default MessengerAppConfig;
