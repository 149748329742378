import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const SettingsApp = lazyWithRetries(() => import("./SettingsApp"));

/**
 * The chat app config.
 */
const SettingsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "advanced-settings",
      element: <SettingsApp />,
      auth: [Role.ADMIN],
    },
  ],
};

export default SettingsAppConfig;
