import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const ContactsApp = lazyWithRetries(() => import("./ContactsApp"));

/**
 * The chat app config.
 */
const ContactsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "contacts",
      element: <ContactsApp />,
      auth: [Role.CONTACTS],
    },
  ],
};

export default ContactsAppConfig;
