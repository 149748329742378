import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import { Navigate } from "react-router-dom";
import settingsConfig from "app/configs/settingsConfig";
import { FuseRouteConfigsType, FuseRoutesType } from "@fuse/utils/FuseUtils";
import SignInConfig from "../main/sign-in/SignInConfig";
import Error404Page from "../main/404/Error404Page";
import MessengerAppConfig from "../main/pages/messenger/MessengerAppConfig";
import CalendarAppConfig from "@@app/main/pages/calendar/CalendarAppConfig";
import MessagesAppConfig from "@@app/main/pages/messages/MessagesAppConfig";
import ContactsAppConfig from "@@app/main/pages/contacts-page/ContactsAppConfig";
import BotEditorAppConfig from "@@app/main/pages/BotEditor/BotEditorAppConfig";
import SettingsAppConfig from "@@app/main/pages/settings/SettingsAppConfig";
import DashboardAppConfig from "@@app/main/pages/dashboard/DashboardAppConfig";
import GptAppConfig from "@@app/main/pages/Gpt/GptAppConfig";
import TeamAppConfig from "@@app/main/pages/team/TeamAppConfig";
import BroadcastAppConfig from "@@app/main/pages/broadcast/BroadcastAppConfig";
import ContactsGroupsAppConfig from "@@app/main/pages/contactsGroups/ContactsGroupsAppConfig";
import RedirctToAuthPage from "@fuse/utils/RedirctToAuthPage";
import CompanyMessagesAppConfig from "@@app/main/pages/companyMessages/ComoanyMessagesAppConfig";
import ConsultationAppConfig from "@@app/main/pages/consultation/ConsultationAppConfig";
import BotSettingsAppConfig from "@@app/main/pages/BotSettings/BotSettingsAppConfig";

export const routeConfigs: FuseRouteConfigsType = [
  DashboardAppConfig,
  MessengerAppConfig,
  BroadcastAppConfig,
  CalendarAppConfig,
  MessagesAppConfig,
  ContactsAppConfig,
  BotEditorAppConfig,
  SettingsAppConfig,
  GptAppConfig,
  TeamAppConfig,
  ContactsGroupsAppConfig,
  SignInConfig,
  CompanyMessagesAppConfig,
  ConsultationAppConfig,
  BotSettingsAppConfig,
  {
    settings: {
      layout: {
        config: {
          navbar: {
            display: false,
          },
          toolbar: {
            display: false,
          },
          footer: {
            display: false,
          },
          leftSidePanel: {
            display: false,
          },
          rightSidePanel: {
            display: false,
          },
        },
      },
    },
    routes: [
      {
        path: "404",
        element: <Error404Page />,
      },
    ],
  },
  // DocumentationConfig,
  // ...PagesConfigs,
  // ...UserInterfaceConfigs,
  // ...DashboardsConfigs,
  // ...AppsConfigs,
  // ...authRoleExamplesConfigs
];

/**
 * The routes of the application.
 */
const routes: FuseRoutesType = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <RedirctToAuthPage />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },

  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
