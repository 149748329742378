import { SettingsData, Token } from "@@app/store/slices/settings";
import ApiService from "./ApiService";

export const getSettingsApi = () => {
  return ApiService.fetchData<SettingsData[]>({
    url: "/settings",
    method: "get",
  });
};

export const getTokensApi = () => {
  return ApiService.fetchData<Token[]>({
    url: "/settings/token",
    method: "get",
  });
};
export const createTokensApi = () => {
  return ApiService.fetchData<Token>({
    url: "/settings/token",
    method: "post",
  });
};

export const deleteTokensApi = (id: number) => {
  return ApiService.fetchData<Token>({
    url: `/settings/token/${id}`,
    method: "delete",
  });
};

export const updateSettingsApi = (data: SettingsData) => {
  return ApiService.fetchData<SettingsData>({
    url: "/settings",
    method: "post",
    data,
  });
};
export const getConfigApi = () => {
  return ApiService.fetchData({
    url: "/settings/config",
    method: "get",
  });
};
