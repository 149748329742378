import "./styles/app-base.css";
import "./styles/app-components.css";
import "./styles/app-utilities.css";
import "./styles/_upload.css";
import "swiper/css";

import { createRoot } from "react-dom/client";
import App from "./app/App";
import { SnackbarProvider } from "notistack";
import "./locales/i18n";
import * as Sentry from "@sentry/react";

// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';
// Get the current hostname dynamically
const currentHost = window.location.hostname;

// Dynamically configure tracePropagationTargets for any host
const tracePropagationTargets = new RegExp(`^https://${currentHost}/api`);

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://48d87992b19477ee7596afdee158742e@o4507940439588864.ingest.de.sentry.io/4507940445814864",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", tracePropagationTargets],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

/**
 * The root element of the application.
 */
const container = document.getElementById("root");

if (!container) {
  throw new Error("Failed to find the root element");
}

/**
 * The root component of the application.
 */
const root = createRoot(container);

root.render(
  <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    classes={{
      containerRoot: "mt-52 md:mb-68 mr-8 lg:mr-80 z-99",
    }}
  >
    <App />
  </SnackbarProvider>
);

// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
