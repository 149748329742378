import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const BroadcastApp = lazyWithRetries(() => import("./BroadcastApp"));

/**
 * The chat app config.
 */
const BroadcastAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "broadcast",
      element: <BroadcastApp />,
      auth: [Role.BULK_MESSAGES],
    },
  ],
};

export default BroadcastAppConfig;
