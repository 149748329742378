import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const MessagesApp = lazyWithRetries(() => import("./MessagesApp"));

/**
 * The chat app config.
 */
const MessagesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "messages",
      element: <MessagesApp />,
      auth: [Role.MESSAGES_PAGE],
    },
  ],
};

export default MessagesAppConfig;
