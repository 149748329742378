import axios from "axios";

import jwtAuthConfig from "@@app/auth/services/jwt/jwtAuthConfig";

const unauthorizedCode = [401];
// import.meta.env.VITE_APP_BASEURL_DEV,

const BaseService = axios.create({
  timeout: 60000,
  baseURL:
    import.meta.env.MODE === "production" ? "/api" : "http://127.0.0.1:443/api",
});
BaseService.interceptors.request.use(
  (config) => {
    // const rawPersistData = localStorage.getItem("PERSIST_STORE_NAME");
    // const persistData = JSON.parse(rawPersistData);

    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const accessToken = localStorage.getItem(jwtAuthConfig.tokenStorageKey);

    // if (!accessToken) {
    //   const { session } = store.getState();
    //   accessToken = session.token;
    // }

    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    const token = localStorage.getItem(jwtAuthConfig.tokenStorageKey);

    if (response && unauthorizedCode.includes(response.status) && token) {
      localStorage.removeItem("whatsbot");
      localStorage.removeItem(jwtAuthConfig.tokenStorageKey);
      window.location.href = "/sign-in";
    }

    return Promise.reject(error);
  }
);

export default BaseService;
