import { SettingsData, Token } from "@@app/store/slices/settings";
import ApiService from "./ApiService";
import { BotSettings } from "@@types/botSettings";

export const getBotSettingsApi = () => {
  return ApiService.fetchData<BotSettings>({
    url: "/bot-master",
    method: "get",
  });
};

export const updateBotSettingsApi = (data: SettingsData) => {
  return ApiService.fetchData<BotSettings>({
    url: "/bot-master",
    method: "post",
    data,
  });
};
