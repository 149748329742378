import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { z } from "zod";
import _ from "@lodash";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import useJwtAuth from "../useJwtAuth";
import { selectUser } from "@@app/store/slices/userSlice";
import { useAppSelector } from "@@app/store/hooks";
import RedirctToAuthPage from "@fuse/utils/RedirctToAuthPage";
import { Typography } from "@mui/material";

/**
 * Form Validation Schema
 */
const schema = z.object({
  username: z.string().nonempty("You must enter an username"),
  password: z
    .string()
    .min(4, "Password is too short - must be at least 4 chars.")
    .nonempty("Please enter your password."),
});

type FormType = {
  username: string;
  password: string;
  remember?: boolean;
};

const defaultValues = {
  username: "",
  password: "",
  remember: true,
};

function JwtSignInForm() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { signIn } = useJwtAuth();

  const { control, formState, handleSubmit, setValue } = useForm<FormType>({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    // setValue('email', 'admin@fusetheme.com', { shouldDirty: true, shouldValidate: true });
    // setValue('password', 'admin', { shouldDirty: true, shouldValidate: true });
  }, [setValue]);

  async function onSubmit(formData: FormType) {
    setLoading(true);
    const { username, password } = formData;
    const res = await signIn({
      username,
      password,
    });
    setLoading(false);

    if (!(res as any)?.access_token) {
      setError(true);
      return;
    }
    setError(false);
  }

  return (
    <form
      name="loginForm"
      noValidate
      className="mt-32 flex w-full flex-col justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && (
        <Typography className="text-red-600 mb-10">
          Invalid username or password
        </Typography>
      )}
      <Controller
        name="username"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mb-24"
            label="Username"
            autoFocus
            type="email"
            error={!!errors.username}
            helperText={errors?.username?.message}
            variant="outlined"
            required
            fullWidth
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mb-24"
            label="Password"
            type="password"
            error={!!errors.password}
            helperText={errors?.password?.message}
            variant="outlined"
            required
            fullWidth
          />
        )}
      />

      <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
        <Controller
          name="remember"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormControlLabel
                label="Remember me"
                control={<Checkbox size="small" {...field} />}
              />
            </FormControl>
          )}
        />
      </div>

      <Button
        variant="contained"
        color="secondary"
        className=" mt-16 w-full"
        aria-label="Sign in"
        disabled={_.isEmpty(dirtyFields) || !isValid || loading}
        type="submit"
        size="large"
      >
        Sign in
      </Button>
    </form>
  );
}

export default JwtSignInForm;
