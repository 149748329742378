import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const Text = lazyWithRetries(() => import("./pages/text/TextMessages"));
const TextDetails = lazyWithRetries(() => import("./ui/MessageDetails"));
const Contact = lazyWithRetries(
  () => import("./pages/contact/ContactMessages")
);
const Image = lazyWithRetries(() => import("./pages/image/ImageMessages"));
const Video = lazyWithRetries(() => import("./pages/video/VideoMessages"));
const Document = lazyWithRetries(
  () => import("./pages/document/DocumentMessages")
);
const Location = lazyWithRetries(
  () => import("./pages/location/LoactionMessages")
);
const Audio = lazyWithRetries(() => import("./pages/audio/AudioMessages"));
// const FlowEditor = lazyWithRetries(() => import("./FlowEditor"));
const Interactive = lazyWithRetries(
  () => import("./pages/interactive/InteractiveMessages")
);
const Template = lazyWithRetries(
  () => import("./pages/template/TemplateMessages")
);

/**
 * The chat app config.
 */
const CompanyMessagesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "companyMessages/text",
      element: <Text />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/audio",
      element: <Audio />,
      auth: [Role.MESSAGES_COMPANY],
    },

    {
      path: "companyMessages/contact",
      element: <Contact />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/image",
      element: <Image />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/video",
      element: <Video />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/document",
      element: <Document />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/location",
      element: <Location />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/interactive",
      element: <Interactive />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/template",
      element: <Template />,
      auth: [Role.MESSAGES_COMPANY],
    },
    // {
    //   path: "companyMessages/FlowEditor",
    //   element: <FlowEditor />,
    //   auth: [Role.MESSAGES_COMPANY],
    // },
  ],
};

export default CompanyMessagesAppConfig;
