import { memo } from 'react';

/**
 * The left side layout 1.
 */
function LeftSideLayout1() {
	return <div />;
}

export default memo(LeftSideLayout1);
