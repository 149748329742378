import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const GptApp = lazyWithRetries(() => import("./Gpt"));

/**
 * The chat app config.
 */
const GptAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "gpt",
      element: <GptApp />,
      auth: [Role.GPT_PAGE],
    },
  ],
};

export default GptAppConfig;
